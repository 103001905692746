// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fictie-tsx": () => import("./../../../src/pages/fictie.tsx" /* webpackChunkName: "component---src-pages-fictie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-over-tsx": () => import("./../../../src/pages/over.tsx" /* webpackChunkName: "component---src-pages-over-tsx" */),
  "component---src-pages-voorwaarden-tsx": () => import("./../../../src/pages/voorwaarden.tsx" /* webpackChunkName: "component---src-pages-voorwaarden-tsx" */),
  "component---src-pages-werk-tsx": () => import("./../../../src/pages/werk.tsx" /* webpackChunkName: "component---src-pages-werk-tsx" */),
  "component---src-templates-user-page-tsx": () => import("./../../../src/templates/userPage.tsx" /* webpackChunkName: "component---src-templates-user-page-tsx" */)
}

